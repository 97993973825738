@import "bootstrap/dist/css/bootstrap.min.css";
@import "./VideoLib/index.scss";
@import "./AudioLib/index.scss";
@import "@fortawesome/fontawesome-free/css/all.css";
@import "react-responsive-carousel/lib/styles/carousel.min.css";
@import "react-phone-number-input/style.css";

$theme-gray: #c3cfd9;
$theme-primary-color: #ff0202;
$theme-primary-color-dark: #cd0000;
$theme-secondary-color: #e00096;
$theme-primary-color-lightest: #ffefe9;
$theme-primary-color-lighter: #ffd1c3;
$theme-primary-color-text: #f8a990;
$theme-primary: linear-gradient(to bottom right, $theme-primary-color, $theme-secondary-color);
$theme-primary-band: #eee; //linear-gradient(to bottom right, #ffbaa4, #ff916f);
$theme-primary-dark: linear-gradient(to bottom right, $theme-secondary-color, $theme-primary-color);
$error: red;

$rhap_theme-color: $theme-primary-color !default; // Color of all buttons and volume/progress indicators
$rhap_background-color: $theme-primary-color-lightest !default; // Color of the player background
$rhap_bar-color: $theme-primary-color-lighter !default; // Color of volume and progress bar
$rhap_time-color: $theme-primary-color !default; // Font color of current time and duration
$rhap_font-family: inherit !default; // Font family of current time and duration

@import "react-h5-audio-player/src/styles.scss";

body {
    margin: 0;
    font-family: "Avenir LT Std", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    * {
        font-family: "Avenir LT Std", sans-serif;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.btn.btn-link {
    padding: 0.375rem;
    color: $theme-primary-color;
    &:hover {
        color: $theme-primary-color-dark;
    }
}
select,
input,
textarea {
    transition: all 300ms;
}
input,
textarea {
    border: 2px solid $theme-gray;
    padding: 10px;
    border-radius: 4px;
    &:active,
    &:focus,
    &:active {
        outline: none;
        border: 2px solid $theme-primary-color;
    }
}
select.form-select:focus,
.btn:focus {
    border-color: $theme-primary-color;
    outline: none;
    box-shadow: 0 0 0 1px $theme-primary-color;
}

.landing-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.75);
    h1 {
        font-weight: bold;
        font-size: 4rem;
        padding: 3rem;
    }
    .text-shadow {
        background: linear-gradient(to right, $theme-primary-color, $theme-secondary-color);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
h2 {
    font-weight: 555;
    font-size: 2.5rem;
}
@media only screen and (max-width: 1000px) {
    .landing-title {
        position: relative;
        h1 {
            font-weight: bold;
            font-size: 2.5rem;
            padding: 2rem;
        }
    }
    h2 {
        font-weight: 555;
        font-size: 2rem;
    }
}
.card {
    border: 2px solid $theme-gray;
    padding: 3rem 4rem;
    max-width: 700px;
    margin: auto;
}

@media only screen and (max-width: 600px) {
    .card {
        padding: 1rem 2rem;
    }
}

.fas {
    color: $theme-gray;
}

.add-guest {
    .fa-plus-circle {
        color: $theme-primary-color;
    }
    span {
        color: $theme-primary-color;
        cursor: pointer;
    }
}

.fixed-footer {
    transition: all 200ms ease-in-out;
    background-color: white;
    font-size: 20px;
    border-top: 2px solid #e7e7e7;
    text-align: center;
    padding: 14px 20px;
    position: fixed;
    left: 0;
    bottom: -80px;
    height: 76px;
    width: 100%;
    z-index: 10;
    box-shadow: 0rem -0.5rem 2rem rgba(0, 0, 0, 0.1) !important;
    &.visible {
        bottom: -1px;
    }
}

.clickable-image {
    cursor: pointer;
    border-radius: 5px;
    &:hover {
        background-color: #f9f9f9;
    }
}

.larger-font {
    font-size: 20px !important;
    font-weight: 600;
}

.active-text,
.active-text.fas {
    cursor: pointer;
    color: $theme-primary-color;
}

.text-normal {
    color: #555;
}
.text-white {
    color: white;
}
.text-success {
    color: green;
}
.text-warning {
    color: orange;
}
.text-muted {
    color: $theme-gray !important;
}
.text-prewrap {
    white-space: pre-wrap;
}
.btn {
    padding: 0.5rem 0.75rem;
}
.btn.btn-primary {
    transition: background 300ms;
    background: $theme-primary;
    border-color: $theme-primary-color !important;
    .fas,
    .far {
        color: white;
    }
    &:hover {
        background: $theme-primary-dark;
    }
    &:disabled {
        border-color: $theme-gray !important;
        background: $theme-gray;
    }
}

.btn.btn-outline-primary,
.button.button-outline-primary {
    transition: background 300ms;
    color: $theme-primary-color;
    border-color: $theme-primary-color;
    .fas,
    .far {
        color: $theme-primary-color;
    }
    &:hover {
        color: $theme-primary-color;
        background: $theme-primary-color-lighter;
    }
    &:disabled {
        color: $theme-gray;
        border-color: $theme-gray;
        .fas,
        .far {
            color: $theme-gray;
        }
    }
}
.date-container {
    position: relative;
    input[type="date"] {
        position: relative;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        color: transparent;
        background: transparent;
    }
    .fa-calendar {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}
.addon {
    cursor: pointer;
    color: $theme-gray;
    border: 3px dashed $theme-gray;
    padding: 8px 20px;
    border-radius: 4px;
    position: relative;
    &:hover,
    &.enabled {
        color: $theme-primary-color;
        border-color: $theme-primary-color;
        .far,
        .fas {
            color: $theme-primary-color;
        }
    }
    &.enabled {
        background-color: $theme-primary-color-lightest;
    }
    .checkbox {
        opacity: 0;
        position: absolute;
        right: 10px;
        top: 2px;
    }
}
.add-gift-card {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 5px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border, color 0.24s ease-in-out;
    cursor: pointer;
    &:hover {
        color: $theme-primary-color !important;
        border-color: $theme-primary-color !important;
    }
    .fas {
        color: inherit;
    }
}

.available-gift-cards {
    border-radius: 5px;
    background-color: #f5f5f5;

    .brand {
        position: relative;
        border: 2px solid white;

        .add-brand {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .select-indicator {
            color: green;
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            font-size: 24px;
            transform: translate(50%, -50%);
            background: radial-gradient(white 50%, transparent 50%);
        }
        &.selected {
            border-color: $theme-primary-color !important;
            .select-indicator {
                display: block;
            }
        }
        &:hover {
            .add-brand {
                display: block;
            }
        }
    }
}

.menu-icon {
    path {
        stroke: $theme-primary-color !important;
    }
}

.file-drop-container {
    cursor: pointer;
    &:hover,
    &:active {
        border-color: $theme-primary-color !important;
    }
}
.otp-input {
    &:hover {
        border-bottom: 3px solid #707070 !important;
    }
    &:active,
    &:focus {
        border-bottom: 3px solid $theme-primary-color !important;
    }
    &.error {
        border-radius: 5px !important;
        border: 3px solid $error !important;
    }
    .PhoneInputInput {
        border: none !important;
    }
}
.vidinvite-creator {
    max-width: 900px;
    margin: 2rem auto;
}
.error {
    outline: 0 none;
    border: 1px solid $error;
}
.error-phone input {
    outline: 0 none;
    border: 2px solid $error;
}
.review-page .PhoneInputCountry {
    display: none;
}
.clipboard-copy-container {
    display: flex;
    border: 3px solid $theme-gray;
    color: $theme-gray;
    padding: 3px;
    border-radius: 5px;
    line-height: 38px;
    i {
        line-height: 38px;
    }
}
.share-button {
    background: transparent;
    border: none;
    &:hover {
        opacity: 0.75;
    }
}
.custom-link {
    cursor: pointer;
    color: #ff0202 !important;
}
.redo-button {
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
}
.dashboard-detail {
    min-height: calc(100vh - 300px);
}
.dashboard-title {
    padding-top: 1.5rem;
}
.cover-image {
    width: 100%;
    max-height: 400px;
    border-radius: 0 0 20px 20px;
    object-fit: cover;
    overflow: hidden;
}
.profile-image {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-left: 40px;
    margin-top: -54px;
    border: 4px solid white;
    &.text {
        font-size: 62px;
        background: #ccc;
        color: white;
    }
}
.username {
    padding: 0.5rem 0 0.5rem 3rem;
    font-size: calc(1.3rem + 0.6vw);
}
@media only screen and (max-width: 550px) {
    .cover-image {
        max-height: 200px;
    }
    .profile-image {
        margin-left: 20px;
        &.text {
            font-size: 62px;
        }
    }
    .username {
        padding: 0.5rem 0 0.5rem 1rem;
        font-size: calc(1rem + 0.6vw);
    }
}
@media only screen and (min-width: 1000px) {
    .hero-container {
        height: calc(100vh - 60px);
        .hero-image-container {
            height: calc(100vh - 60px);
            img {
                max-height: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .dashboard-detail {
        min-height: calc(100vh - 324px);
    }
    .dashboard-title {
        padding-top: 0.5rem;
    }
}
.celebration-actions-container {
    background: $theme-primary-color-lighter;
    button,
    .fas {
        color: white;
        font-size: 0.875rem;
        text-transform: none;
    }
}
.transition {
    transition: all 250ms;
}
.cancel-button {
    border: 1px solid $theme-primary-color;
    color: $theme-primary;
}
@media screen and (min-width: 576px) {
    .crop-buttons {
        display: flex !important;
        width: 100% !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .cancel-button {
        width: 50% !important;
    }
    .crop-image-button {
        width: 50% !important;
    }
}
.container-padding-bottom {
    padding-bottom: 100px !important;
}
.video-container {
    max-width: 800px;
    height: auto;
    margin: auto;
}
.audio-container {
    max-width: 500px;
    width: 100%;
    margin: auto;
}
.MuiStepper-root.MuiStepper-horizontal {
    max-width: 700px;
    margin: auto;
}
.MuiSvgIcon-root {
    font-size: 1rem !important;
}
.MuiDialogContent-root {
    position: relative;
    padding-bottom: 0px !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}
.referral-popup {
    .MuiPaper-root.MuiPaper-elevation {
        width: 100%;
        max-width: 800px;
    }
    .MuiDialogContent-root {
        padding: 0 !important;
        width: 100%;
    }
}
.MuiDialogActions-root {
    width: 100% !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    justify-content: center !important;
    align-items: center !important;
}
@media only screen and (max-width: 600px) {
    .MuiDialogContent-root {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .referral-popup {
        .MuiPaper-root.MuiPaper-elevation {
            margin: 10px;
        }
        .MuiDialogContent-root {
            padding: 0 !important;
        }
    }
    .MuiDialogActions-root {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
    width: 100%;
    margin: 5px;
}
.MuiSvgIcon-fontSizeMedium {
    font-size: 1rem !important;
}
.MuiFormControlLabel-label {
    font-family: "Fira Sans", sans-serif !important;
}
.Mui-completed,
.Mui-active {
    .MuiStepLabel-iconContainer > div,
    .MuiStepConnector-line {
        background-color: $theme-primary !important;
        border-radius: 50%;
    }
    .MuiStepLabel-alternativeLabel {
        color: $theme-primary !important;
    }
}
.MuiRadio-colorPrimary.Mui-checked {
    color: $theme-primary !important;
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
    text-transform: none !important;
    font-weight: 500;
    font-size: 16px;
    &.Mui-selected {
        color: $theme-primary-color !important;
    }
}
.MuiButton-outlined.MuiButton-outlinedPrimary {
    &:hover {
        border-color: $theme-primary-color-text;
    }
}
.MuiFormControlLabel-root {
    margin-right: 0 !important;
}
.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.Mui-checked,
.MuiRadio-root.MuiRadio-colorPrimary.MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.Mui-checked {
    color: $theme-primary-color;
}
.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall {
    text-transform: none;
    font-size: 1rem;
}
.MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3 !important;
}
.close-popup-btn.MuiButtonBase-root.MuiIconButton-root {
    position: absolute;
    top: 30px;
    right: 20px;
}
.MuiIconButton-root.MuiIconButton-sizeMedium i {
    font-size: 1rem;
}
.small-padding {
    .MuiPaper-root.MuiPaper-elevation {
        margin: 16px;
    }
    .MuiDialogContent-root {
        padding: 24px !important;
    }
}
.MuiPagination-root {
    margin: 0 auto;
}
.MuiBadge-badge.MuiBadge-standard {
    transform: scale(1) translate(40%, -50%);
    &.MuiBadge-colorPrimary {
        background-color: $theme-primary-color;
    }
    &.MuiBadge-colorInfo {
        background-color: $theme-gray;
    }
}
.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
    background-color: $theme-primary-color-lighter;
}
.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.bg-white {
    border: 1px solid $theme-primary-color-lighter;
    color: $theme-primary-color-lighter;
}
.MuiIconButton-colorPrimary {
    .fa,
    .fas {
        color: $theme-primary-color;
        font-size: 18px;
        padding: 4px;
    }
}
.MuiMenuItem-root {
    .fa,
    .fas {
        color: $theme-primary-color;
    }
}
.MuiAccordion-root.no-shadow {
    box-shadow: none;
    &:before {
        height: 0;
    }
}
.MuiAccordionSummary-expandIconWrapper {
    .MuiSvgIcon-root {
        fill: $theme-primary-color;
    }
}
.MuiAccordionSummary-content {
    color: $theme-primary-color;
}
.MuiButtonBase-root.MuiChip-root.MuiChip-sizeMedium.MuiChip-colorPrimary {
    border-color: $theme-primary-color !important;
    color: $theme-primary-color !important;
}
.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorPrimary.MuiChip-filledPrimary {
    border-color: $theme-primary-color !important;
    background-color: $theme-primary-color !important;
    color: white !important;
}
.MuiTypography-root.MuiLink-root {
    text-decoration-color: rgba(255, 2, 2, 0.4);
}
.guest-list {
    .MuiFormGroup-root {
        max-height: 120px;
        padding: 15px;
        border: 1px solid $theme-gray;
        flex-wrap: nowrap;
        overflow: scroll;
    }
}
.remove-padding {
    .MuiDialogContent-root {
        padding: 0px !important;
    }
}
.private-post-info {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .label {
        background-color: rgba(255, 255, 255, 0.5);
        padding: 5px;
        border-radius: 4px;
    }
}
.blur-post {
    pointer-events: none;
    opacity: 0.6;
    filter: blur(4px);
    -webkit-filter: blur(4px);
}
.font-italic {
    font-style: italic;
}
table {
    td {
        padding: 0.5rem;
        word-break: break-all;
        .fas,
        .far {
            color: $theme-primary-color-text;
        }
    }
    & > :not(:first-child) {
        border-top: 2px solid #eee !important;
    }
    &.dashed-table > tr {
        border-top: 3px dashed #eee !important;
    }
    &.dashed-table > :last-child {
        border-top: 3px solid #eee !important;
    }
}
.border,
.border-top,
.border-bottom {
    border-color: #eee !important;
}
.bg-darker {
    background: #edf1f5;
}
.bg-lightest {
    background: #fdfdfd;
}
.bg-highlighted {
    background: $theme-primary-color-lighter;
    text-shadow: 0 0 white;
    color: white;
}
.disabled {
    pointer-events: none;
    opacity: 0.5;
}
input:disabled {
    cursor: not-allowed !important;
}
.slide {
    .element-blur {
        transition: opacity 300ms;
    }
    &:not(.selected) {
        .element-blur {
            pointer-events: none;
            opacity: 0.6;
            filter: blur(4px);
            -webkit-filter: blur(4px);
        }
    }
}
.selected-gift {
    min-width: 100;
    position: relative;
    .delete-gift {
        position: absolute;
        display: none;
        right: 5px;
        top: 0;
        background: white;
        .fas {
            color: red;
        }
    }
    &:hover {
        .delete-gift {
            display: block;
        }
    }
}

.carousel .btn {
    position: absolute;
    z-index: 2;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: $theme-primary;
    background: white;
    &.btn-left {
        left: 10px;
    }
    &.btn-right {
        right: 10px;
    }
}
@media only screen and (min-width: 768px) {
    .carousel .btn.btn-primary {
        top: calc(50% - 25px);
        width: 50px;
        height: 50px;
        &.btn-left {
            left: 15px;
        }
        &.btn-right {
            right: 15px;
        }
    }
}
.landing-link {
    cursor: pointer;
    color: #555;
    transition: all 300ms;
    .fas {
        color: #555;
    }
    &.vidinvite-option {
        max-width: 400px;
        .img-container {
            height: 180px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }
    &:hover {
        color: $theme-primary-color;
        border-color: $theme-primary-color;
        .fas {
            color: $theme-primary-color;
        }
    }
}
.contact-picker-modal,
.customize-guest-picker-modal {
    width: 40vw;
    max-width: 500px;
    min-width: 300px;
}
.float-right {
    float: right;
}
.highlighted,
.highlighted .fas {
    color: $theme-primary-color;
}
.full-width-container {
    margin: auto;
    .landing-sample-invite {
        cursor: pointer;
        position: relative;
        border-radius: 5px;
        height: 100%;
        padding: 5px;
        .landing-sample-invite-container {
            background: white;
            img {
                max-height: 400px;
                max-width: 100%;
                height: 100%;
                width: auto;
                display: block;
                object-fit: contain;
                transition: opacity 300ms;
            }
            .text {
                text-align: center;
                padding: 10px;
                float: left;
                color: #555;
            }
            .play-icon {
                color: #eee;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
            }
        }
    }
}
.font-normal {
    font-weight: 300;
    font-size: 100% !important;
}
.font-dark {
    color: #113151 !important;
}
.font-lightest {
    color: #a8a8a8 !important;
}
.landing-page-container {
    max-width: 720px;
    margin: auto;
}
.colored-band {
    background: $theme-primary-band;
}
.alert-container {
    max-width: 500px;
}
.vidinvite-container {
    max-width: 500px;
    margin: auto;
    border-radius: 5px;
    img {
        width: 100%;
    }
}
.dashboard-card {
    cursor: pointer;
    background: white;
    &:hover {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }
}
.login-container {
    max-width: 500px;
    // min-height: calc(100vh - 120px);
    .subtitle {
        text-align: center;
        margin-bottom: 1rem;
        font-size: 15px;
    }
    .smalltitle {
        text-align: center;
        margin-bottom: 1rem;
        font-size: 13px;
    }
    .login-card {
        margin: auto;
        .social-buttons {
            .btn {
                line-height: 1rem;
                font-weight: normal;
                font-size: 1.1rem;
            }
        }

        .google-button,
        .apple-button {
            padding: 10px;
            margin: 0.36rem !important;
            padding: 15px;
            border: 1px solid $theme-gray;
        }

        .icon {
            width: 23px;
        }
    }
}
.version-label {
    font-weight: bold;
    color: #999;
    margin: 0 auto 10px;
}
.preview-buttons {
    top: 1rem;
    left: 0;
    right: 0;
    padding: 0 1rem;
}
.early-access-promotion {
    position: relative;
    width: 100vw;
    height: calc(100vh - 130px);
    min-height: 500px;
    overflow: hidden;
    .background-image {
        background-image: url("https://vidinvite-public.s3.us-west-1.amazonaws.com/landing.webp");
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        filter: blur(8px);
        -webkit-filter: blur(8px);
        object-fit: contain;
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .promo-container {
        background: rgba(255, 255, 255, 0.3);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .promo-title {
        // background: linear-gradient(to right, $theme-primary-color, $theme-secondary-color);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        width: 100%;
        padding: 20px;
        font-weight: 700;
        font-size: 7vw;
        text-align: center;
        &.absolute {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .early-access-btn {
        margin-top: 1rem;
    }
    .btn.btn-primary {
        font-size: 1.5vw;
    }
}
.pulse-container {
    position: absolute;
    bottom: 0;
    left: calc(13.5% - 5px);
    width: 20px;
    animation: slidePulse 8s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
    .pulse {
        padding: 0;
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $theme-primary-color;
        box-shadow: 0 0 0 $theme-primary-color-lightest;
        animation: pulse 2s infinite;
    }
}
.hero-floating-labels {
    position: relative;
    overflow: hidden;
    height: 100%;
    .floating-label {
        z-index: 2;
        background-color: $theme-primary-color-lightest;
        padding: 6px 12px;
        border-radius: 18px;
        border-right: solid 4px #fff;
        border-bottom: solid 5px #fff;
        font-weight: bold;
        font-size: 20px;
        color: #21123dc9;
    }
    .move-slower {
        -webkit-animation: moveInCircle 320s linear infinite;
        -moz-animation: moveInCircle 32s linear infinite;
        -o-animation: moveInCircle 32s linear infinite;
        animation: 32000ms linear 0ms infinite normal none running moveInCircle;
    }
    .move-slow {
        -webkit-animation: moveInCircle 25s linear infinite;
        -moz-animation: moveInCircle 25s linear infinite;
        -o-animation: moveInCircle 25s linear infinite;
        animation: 25000ms linear 0ms infinite normal none running moveInCircle;
    }
    .top-left {
        position: absolute;
        top: 15%;
        left: 20%;
    }
    .top-right {
        position: absolute;
        top: 18%;
        right: 14%;
    }
    .btm-right {
        position: absolute;
        bottom: 28%;
        right: 12%;
    }
    .btm-left {
        position: absolute;
        bottom: 32%;
        left: 10%;
    }
}
@media only screen and (max-width: 767px) {
    .hero-floating-labels {
        .floating-label {
            font-size: 14px;
        }
    }
}
@media only screen and (max-width: 580px) {
    .hero-floating-labels {
        .move-slower {
            &.hide-show {
                -webkit-animation: moveInCircle 320s linear infinite, hideShow 25s linear infinite;
                -moz-animation: moveInCircle 32s linear infinite, hideShow 25s linear infinite;
                -o-animation: moveInCircle 32s linear infinite, hideShow 25s linear infinite;
                animation: 32000ms linear 0ms infinite normal none running moveInCircle, 25000ms linear 0ms infinite normal none running hideShow;
            }
            &.show-hide {
                -webkit-animation: moveInCircle 25s linear infinite, showHide 25s linear infinite;
                -moz-animation: moveInCircle 25s linear infinite, showHide 25s linear infinite;
                -o-animation: moveInCircle 25s linear infinite, showHide 25s linear infinite;
                animation: 25000ms linear 0ms infinite normal none running moveInCircle, 25000ms linear 0ms infinite normal none running showHide;
            }
        }
        .move-slow {
            &.hide-show {
                -webkit-animation: moveInCircle 320s linear infinite, hideShow 25s linear infinite;
                -moz-animation: moveInCircle 32s linear infinite, hideShow 25s linear infinite;
                -o-animation: moveInCircle 32s linear infinite, hideShow 25s linear infinite;
                animation: 32000ms linear 0ms infinite normal none running moveInCircle, 25000ms linear 0ms infinite normal none running hideShow;
            }
            &.show-hide {
                -webkit-animation: moveInCircle 25s linear infinite, showHide 25s linear infinite;
                -moz-animation: moveInCircle 25s linear infinite, showHide 25s linear infinite;
                -o-animation: moveInCircle 25s linear infinite, showHide 25s linear infinite;
                animation: 25000ms linear 0ms infinite normal none running moveInCircle, 25000ms linear 0ms infinite normal none running showHide;
            }
        }
        .floating-label {
            font-size: 14px;
        }
        .top-left {
            top: 15%;
            left: 20%;
        }
        .top-right {
            top: 18%;
            right: 14%;
        }
        .btm-right {
            bottom: 18%;
            right: 12%;
        }
        .btm-left {
            bottom: 12%;
            left: 10%;
        }
    }
}
.word-carousel {
    div {
        overflow: hidden;
        position: relative;
        height: 75px;
        .flip-text {
            animation: flipText 8s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
            li {
                padding: 20px;
                text-align: center;
                height: 45px;
                margin-bottom: 45px;
                display: block;
            }
        }
    }
}

@media only screen and (max-width: 770px) {
    .early-access-promotion {
        .promo-title {
            font-size: 14vw;
        }
        .btn.btn-primary {
            font-size: 4vw;
        }
    }
}
@media only screen and (max-width: 750px) {
    .early-access-promotion {
        .promo-title {
            font-size: 10vw;
        }
    }
}
@media only screen and (max-width: 550px) {
    .early-access-promotion {
        .promo-title {
            font-size: 12vw;
        }
        .btn.btn-primary {
            font-size: 6vw;
        }
    }
}
.landing-title {
    padding: 10px 5px 20px;
    .text-container {
        margin: 0 10px;
        height: 60px;
        position: relative;
        &.text-full {
            height: unset;
            text-align: center;
            color: $theme-primary-color;
            width: 100%;
            font-family: inherit;
            letter-spacing: 4px;
            text-shadow: -1px 0 $theme-secondary-color, 0 1px $theme-secondary-color, 1px 0 $theme-secondary-color, 0 -1px $theme-secondary-color;
            // font-size: 40px;
            font-weight: 700;
            stroke-width: 1;
        }
        svg text {
            width: 100%;
            font-family: inherit;
            letter-spacing: 4px;
            stroke: $theme-secondary-color;
            fill: $theme-primary-color;
            font-size: 40px;
            font-weight: 700;
            stroke-width: 1;
        }
        span {
            font-family: inherit;
            display: inline-block;
            font-size: 40px;
            font-weight: 700;
            margin-right: 3px;
            color: transparent;
            line-height: 72px;
            text-shadow: 0 0 0 $theme-primary-color;
            animation: smoky 5s 3s both alternate;
        }
        span:nth-child(even) {
            animation-name: smoky-mirror;
        }
        @for $item from 1 through 7 {
            span:nth-of-type(#{$item}) {
                animation-delay: #{(1 + ($item/4))}s;
            }
        }
        .underline {
            position: absolute;
            bottom: -8px;
            right: 0;
            height: 5px;
            border: 1px solid $theme-secondary-color;
            background: $theme-primary-color;
            transition: all 1s;
            transition-timing-function: ease;
        }
        &.text-1 {
            width: 120px;
        }
        &.text-2 {
            width: 182px;
        }
        &.text-3 {
            width: 266px;
        }
        &.text-5 {
            width: 98px;
        }
        &.text-6 {
            width: 125px;
        }
        &.rotate-words {
            overflow: hidden;
            position: relative;
            -webkit-animation: rotateWidthMobile 9s ease infinite 0s;
            -ms-animation: rotateWidthMobile 9s ease infinite 0s;
            animation: rotateWidthMobile 9s ease infinite 0s;
            svg {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                overflow: hidden;
                -webkit-animation: rotateWord 9s linear infinite 0s;
                -ms-animation: rotateWord 9s linear infinite 0s;
                animation: rotateWord 9s linear infinite 0s;
            }
            svg:nth-child(2) {
                -webkit-animation-delay: 3s;
                -ms-animation-delay: 3s;
                animation-delay: 3s;
            }
            svg:nth-child(3) {
                -webkit-animation-delay: 6s;
                -ms-animation-delay: 6s;
                animation-delay: 6s;
            }
        }
        &.animate {
            svg text {
                animation: textAnimate 5s infinite alternate;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .landing-title {
        padding: 20px 5px;
        .text-container {
            margin: 0 15px;
            &.text-1 {
                width: 139px;
            }
            &.text-2 {
                width: 216px;
            }
            &.text-3 {
                width: 318px;
            }
            &.text-5 {
                width: 118px;
            }
            &.text-6 {
                width: 151px;
            }
            &.rotate-words {
                overflow: hidden;
                position: relative;
                -webkit-animation: rotateWidth 9s ease infinite 0s;
                -ms-animation: rotateWidth 9s ease infinite 0s;
                animation: rotateWidth 9s ease infinite 0s;
            }
            svg text {
                font-size: 50px;
            }
            // &.text-full {
            //     font-size: 48px;
            // }
            span {
                font-size: 48px;
                height: 50px;
                margin-right: 4px;
                line-height: 64px;
            }
        }
    }
}

@keyframes textAnimate {
    0% {
        stroke-dasharray: 0 50%;
        stroke-dashoffset: 20%;
        fill: $theme-primary-color-lighter;
    }
    100% {
        stroke-dasharray: 50% 0;
        stroke-dashoffset: -20%;
        fill: $theme-primary-color;
    }
}

@keyframes smoky {
    0% {
        transform: translate3d(15rem, -8rem, 0) rotate(-40deg) skewX(70deg) scale(1.5);
        text-shadow: 0 0 40px $theme-primary-color;
        opacity: 0;
        color: transparent;
    }
    60% {
        transform: none;
        opacity: 1;
        color: transparent;
        -webkit-text-stroke: 0;
    }
    100% {
        -webkit-text-stroke: 1px $theme-secondary-color;
        color: $theme-primary-color;
    }
}

@keyframes smoky-mirror {
    0% {
        transform: translate3d(18rem, -8rem, 0) rotate(-40deg) skewX(-70deg) scale(2);
        text-shadow: 0 0 20px $theme-primary-color;
        opacity: 0;
        color: transparent;
    }
    60% {
        transform: none;
        opacity: 1;
        color: transparent;
        -webkit-text-stroke: 0;
    }
    100% {
        -webkit-text-stroke: 1px $theme-secondary-color;
        color: $theme-primary-color;
    }
}
.fa-check-circle {
    color: #008000c2;
}
.thankyou-view {
    .message {
        border: 2px solid $theme-gray;
    }
}
.main-card {
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    .btn {
        line-height: 1rem;
        font-weight: normal;
        font-size: 1.1rem;
        padding: 10px;
        margin: 0.36rem !important;
        padding: 15px;
        border: 1px solid $theme-gray;
    }
}

.companies-card {
    margin: auto;
    display: flex;
    margin-top: 5rem;
    .company {
        padding: 3rem;
        width: 225px;
        height: 225px;
        border: 2px solid $theme-gray;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2rem;
        background-color: $theme-primary-color-lightest;
        cursor: pointer;
        &:active,
        &:focus,
        &:hover,
        &:active {
            outline: none;
            border: 2px solid $theme-primary-color;
        }
    }
}

.message-option-label {
    margin-right: 2rem !important;
}

.modal__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(28, 28, 28, 0.19);
    backdrop-filter: blur(6px);
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    z-index: 100;
}

.modal__align {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.modal__content {
    width: 800px;
    height: 500px;
    box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07), 0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112), 0px 7.779px 7.30492px rgba(0, 0, 0, 0.035),
        0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
    border-radius: 20px;
    background: transparent;
    color: #000;
    margin: 0rem 4rem;
}

.tab-icon {
    color: inherit !important;
}
.pledge-step {
    min-width: 200px;
    border: 3px solid #777;
    border-radius: 10px;
    position: relative;
    color: #333;
    .text-normal {
        color: inherit;
    }
    .title {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .index {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -23px);
        background-color: white;
        border-top-left-radius: 21px;
        border-top-right-radius: 21px;
        border: 3px solid #777;
        border-bottom: 0;
        width: 42px;
        height: 23px;
        .inner {
            border: 4px solid white;
            width: 36px;
            height: 36px;
            text-align: center;
            line-height: 28px;
            border-radius: 50%;
            color: white;
            background: #999;
        }
    }
    &.even {
        background: #777;
        color: white;
        .index {
            background: #777;
            .inner {
                border-color: #777;
                color: #333;
                background: white;
            }
        }
    }
}
.modal-video-close-btn {
    position: relative;
    z-index: 2;
    top: -45px;
    right: 0px;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    float: right;
    border: none;
    background: transparent;
}
.chip-list {
    display: flex;
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
    .chip {
        margin: 0 10px 0 0;
    }
}
.cause-container {
    min-width: 243px;
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    &.selected {
        border-width: 2px !important;
        border-color: $theme-primary-color !important;
    }
    .selected-checkmark {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $theme-primary-color !important;
    }
    &:hover {
        background: #f9f9f9;
    }
    img {
        display: block;
        margin: auto;
        height: 100px;
    }
}

.modal-video-close-btn:before {
    transform: rotate(45deg);
}
.modal-video-close-btn:before,
.modal-video-close-btn:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px;
}
.modal-video-close-btn:after {
    transform: rotate(-45deg);
}

@media (orientation: landscape) {
    .modal-video-close-btn {
        top: 0;
        right: -45px;
    }
}

.modal__video-align {
    display: flex;
    position: relative;
    bottom: 37px;
}

.modal__video-style {
    border-radius: 20px;
    z-index: 100;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 800px) {
    .modal__content {
        margin: 0rem 1rem;
        width: 100%;
    }
    .modal__video-style {
        width: 100%;
    }
}

@media screen and (max-width: 499px) {
    .modal__content {
        background: transparent;
        height: auto;
    }
    .modal__video-align {
        bottom: 0px;
    }
    .modal__video-style {
        height: auto;
    }
}

.addwhitelisted-email {
    min-width: 300px;
}

.popup-width {
    max-width: 400px !important;
}

.custom-link {
    cursor: pointer;
    color: #0d6efd;
    text-decoration: none !important;
}

@media only screen and (max-width: 355px) {
    .popup-width {
        max-width: 200px !important;
    }
}
.contribute-page-container {
    position: relative;
    width: 100%;
    background: white;
    .presentation-mode {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .carousel,
    .slider-wrapper,
    .slider.animated,
    .carousel .slider {
        height: 100%;
    }
    .background-image {
        width: 100%;
        height: 100%;
        filter: blur(3px);
        -webkit-filter: blur(3px);
        object-fit: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        transition: filter 0.5s;
    }
    .centered-message-container {
        background: #eee;
        padding: 2rem;
        .inner {
            background: rgba(255, 255, 255, 0.7);
            padding: 2rem;
        }
    }
    .cover-header {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 90%;
        transform: translate(-50%, -50%);
        padding: 20px 10px;
        background: rgba(255, 255, 255, 0.8);
    }
    .expand-button {
        position: relative;
        animation: pulse 2s infinite;
        .fas.parent {
            position: relative;
            transition: transform 300ms;
            color: $theme-gray;
            .child {
                color: $theme-primary-color;
                position: absolute;
                top: 50%;
                left: 0;
            }
            &.open {
                transform: rotateX(180deg);
            }
        }
        &:hover {
            animation: none;
        }
    }
    .header-video {
        max-height: 600px;
    }
    .banner-icon {
        position: absolute;
        top: 10px;
        left: 20px;
    }
    .share-button {
        position: absolute;
        top: 3px;
        right: 20px;
        .fas {
            font-size: 1.35rem;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 50%;
            padding: 7px;
        }
    }
    .message-container {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin-bottom: 0px;
        position: absolute;
        max-height: 600px;
        margin: 0;
    }
    .message {
        position: relative;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        margin-bottom: 0px;
        flex-direction: column;
        transition: background 0.5s;
    }
    .collapsed {
        height: 0;
        overflow: hidden;
    }
    .video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: auto;
        .w-100 {
            max-width: 100% !important;
            max-height: 100% !important;
        }
    }
    .title {
        font-size: 3.6vw;
    }
    .sub-title {
        font-weight: 300;
        font-size: 2vw;
    }
}
.celebration-page-container {
    position: relative;
    width: 100%;
    background: white;
    min-height: 500px;
    max-height: 600px;
    .presentation-mode {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .carousel,
    .slider-wrapper,
    .slider.animated,
    .carousel .slider {
        height: 100%;
    }
    .share-button {
        position: absolute;
        top: 3px;
        right: 20px;
        .fas {
            font-size: 1.35rem;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 50%;
            padding: 7px;
        }
    }
    .background-image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        filter: blur(3px);
        -webkit-filter: blur(3px);
        object-fit: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        transition: filter 0.5s;
    }
    .banner-icon {
        position: absolute;
        top: 10px;
        left: 20px;
    }
    .message-container {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin-bottom: 0px;
        position: absolute;
        max-height: 600px;
        margin: 0;
        .message {
            height: auto;
            min-height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            margin-bottom: 0px;
            flex-direction: column;
            transition: background 0.5s;
            .title {
                font-size: 3.2vw;
            }
            .sub-title {
                font-weight: 300;
                font-size: 2vw;
            }
            img {
                max-height: 500px;
                object-fit: contain;
            }
        }
        .video-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            margin: auto;
            padding: 0 28px 30px 28px;
            .w-100 {
                max-width: 100% !important;
                max-height: 100% !important;
            }
        }
    }
}
@media only screen and (max-width: 770px) {
    .celebration-page-container {
        min-height: 300px;
    }
    .contribute-page-container {
        .sub-title {
            font-size: 3.5vw;
        }
    }
}
@media only screen and (max-width: 480px) {
    .contribute-page-container {
        .sub-title {
            font-size: 5vw;
        }
    }
}

.celebration-page-container {
    .background-image {
        object-fit: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.rhap_container {
    border-radius: 10px;
}
.animate-character {
    background-image: linear-gradient(-225deg, $theme-primary-color 0%, $theme-secondary-color 50%, $theme-primary-color 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 4s linear infinite;
    display: inline-block;
    font-size: 190px;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

@-webkit-keyframes rotateWord {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
    }
    2% {
        opacity: 1;
        -webkit-transform: translateY(3px);
    }
    3% {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }
    30% {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }
    33% {
        opacity: 0;
        -webkit-transform: translateY(100%);
    }
    40% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@-ms-keyframes rotateWord {
    0% {
        opacity: 0;
        -ms-transform: translateY(-100%);
    }
    2% {
        opacity: 1;
        -ms-transform: translateY(3px);
    }
    3% {
        opacity: 1;
        -ms-transform: translateY(0px);
    }
    30% {
        opacity: 1;
        -ms-transform: translateY(0px);
    }
    33% {
        opacity: 0;
        -ms-transform: translateY(100%);
    }
    40% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@keyframes rotateWord {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    2% {
        opacity: 1;
        -webkit-transform: translateY(3px);
        transform: translateY(3px);
    }
    3% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
    30% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
    33% {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
    40% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes rotateWidth {
    0% {
        width: 155px;
    }
    33% {
        width: 155px;
    }
    36% {
        width: 254px;
    }
    63% {
        width: 254px;
    }
    69% {
        width: 301px;
    }
    96% {
        width: 301px;
    }
    100% {
        width: 155px;
    }
}

@keyframes rotateWidthMobile {
    0% {
        width: 130px;
    }
    33% {
        width: 130px;
    }
    36% {
        width: 210px;
    }
    63% {
        width: 210px;
    }
    69% {
        width: 248px;
    }
    96% {
        width: 248px;
    }
    100% {
        width: 130px;
    }
}

@keyframes flipText {
    0% {
        margin-top: 0;
    }
    30% {
        margin-top: 0;
    }
    36% {
        margin-top: -90px;
    }
    63% {
        margin-top: -90px;
    }
    66% {
        margin-top: -180px;
    }
    96% {
        margin-top: -180px;
    }
    100% {
        margin-top: 5px;
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba($theme-primary-color, 0.4);
        box-shadow: 0 0 0 0 rgba($theme-primary-color, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba($theme-primary-color, 0);
        box-shadow: 0 0 0 10px rgba($theme-primary-color, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba($theme-primary-color, 0);
        box-shadow: 0 0 0 0 rgba($theme-primary-color, 0);
    }
}
@keyframes slidePulse {
    0% {
        left: calc(13.5% - 5px);
    }
    30% {
        left: calc(13.5% - 5px);
    }
    36% {
        left: calc(50% - 5px);
    }
    63% {
        left: calc(50% - 5px);
    }
    66% {
        left: calc(86% - 5px);
    }
    96% {
        left: calc(86% - 5px);
    }
    100% {
        left: calc(13.5% - 5px);
    }
}
@keyframes moveInCircle {
    0% {
        transform: rotate(5deg) translateX(25px) rotate(-5deg);
    }
    100% {
        transform: rotate(360deg) translateX(25px) rotate(-360deg);
    }
}
@keyframes showHide {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    33% {
        opacity: 1;
    }
    66% {
        opacity: 1;
    }
    69% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes hideShow {
    0% {
        opacity: 1;
    }
    30% {
        opacity: 1;
    }
    33% {
        opacity: 0;
    }
    66% {
        opacity: 0;
    }
    69% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
