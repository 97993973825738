@import "@fortawesome/fontawesome-free/css/all.min";
@import url("https://fonts.cdnfonts.com/css/avenir-lt-std");
@import "animate";
@import "react-multi-carousel/lib/styles.css";
@import "react-toastify/dist/ReactToastify.css";

$theme-color: #eb6a74;
$warning-color: #ff7900;
$blue-theme-main-background: #e3f4ff;
$blue-theme-main: #0099ff;
$blue-theme-secondary-hover: #c2e6ff;
$blue-theme-main-dark: #0085dd;

givingjoy-audio-uploader,
givingjoy-audio-player {
    display: block;
    font-size: 13px;
    & * {
        font-family: "Avenir LT Std", sans-serif;
    }
}

.MuiTooltip-tooltip {
    font-family: "Avenir LT Std", sans-serif;
    font-size: 12px !important;
}

.vjs-gratiphied-theme {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    background: transparent;
    transition: all 250ms;
}

.audio-picker-container {
    transition: all 250ms;
    background: $blue-theme-main-background;
    .primary-button .fas {
        color: white;
    }
    .secondary-button .fas {
        color: $blue-theme-main;
    }
}
.audio-picker-choices {
    background: $blue-theme-main-background;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.primary-button,
.secondary-button {
    min-width: 60px;
    padding: 14px 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}
.audio-picker-choices-container .primary-button.vertical,
.audio-picker-choices-container .secondary-button.vertical {
    width: 160px;
}
.vjs-control {
    width: 3em !important;
}
@media only screen and (min-width: 800px) {
    .primary-button,
    .secondary-button {
        width: 152px;
    }
}
.primary-button.vertical i,
.secondary-button.vertical i {
    padding-bottom: 10px;
    font-size: 20px;
}
.primary-button {
    background: $blue-theme-main;
    color: white;
    &:hover {
        background: $blue-theme-main-dark;
    }
}
.primary-button.recording {
    background: red;
    color: white;
    &:hover {
        background: #d80303;
    }
}
.secondary-button {
    color: $blue-theme-main;
    border: 1px solid $blue-theme-main;
    &:hover {
        background: $blue-theme-secondary-hover;
    }
}
.duration-warning-container {
    width: 160px;
    box-sizing: border-box;
    position: relative;
    color: $blue-theme-main;
    border: 1px solid $blue-theme-main;
    min-width: 60px;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    .fa-exclamation-triangle {
        color: $warning-color;
    }
    #progress-bar {
        z-index: -1;
        border-radius: 5px;
        background: $blue-theme-secondary-hover;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 0;
        transition: width 4s linear;
    }
}
.duration-warning-desktop {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
    color: red;
    font-size: 18px;
}
.show-view {
    display: block;
}
.hide-view {
    display: none;
}
.d-flex {
    display: flex;
}
.pt-3 {
    padding-top: 1rem;
}
.m-auto {
    margin: auto;
}
.menu {
    position: relative;
    background: $blue-theme-main-background;
}
.menu-button {
    margin: 1rem auto;
}
.mr-1 {
    margin-right: 0.25rem;
}
.mt-1 {
    margin-top: 0.25rem;
}
.ml-1 {
    margin-left: 0.25rem;
}
.mr-2 {
    margin-right: 0.5rem;
}
.mt-2 {
    margin-top: 0.5rem;
}
.ml-2 {
    margin-left: 0.5rem;
}
.mr-3 {
    margin-right: 1rem;
}
.mt-3 {
    margin-top: 1rem;
}
.ml-3 {
    margin-left: 1rem;
}
.w-100 {
    width: 100%;
}
.player-height {
    max-height: 250px;
}
.display-flex {
    display: flex;
}
.display-flex-column {
    display: flex;
    flex-direction: column;
}
.audio-picker input {
    visibility: hidden;
    position: absolute;
    width: 50%;
}
.audio-picker-choices {
    height: 250px;
}
.recording-control-buttons-container {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.recording-elapsed-time {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.red-recording-dot {
    font-size: 25px;
    color: red;
    margin-right: 12px;
    /*transitions with Firefox, IE and Opera Support browser support*/
    animation-name: flashing-recording-dot;
    -webkit-animation-name: flashing-recording-dot;
    -moz-animation-name: flashing-recording-dot;
    -o-animation-name: flashing-recording-dot;
    animation-duration: 2s;
    -webkit-animation-duration: 2s;
    -moz-animation-duration: 2s;
    -o-animation-duration: 2s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
}
/* The animation code */
@keyframes flashing-recording-dot {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes flashing-recording-dot {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes flashing-recording-dot {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes flashing-recording-dot {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.elapsed-time {
    font-size: 32px;
    margin-bottom: 0px;
}
.audio-player {
    margin: 0 auto;
    padding-top: 1rem;
    width: 90%;
}
audio::-webkit-media-controls-panel {
    background-color: #facaca !important;
}
audio::-internal-media-controls-overflow-button {
    display: none !important;
}
#webRTCVideo .vjs-control-bar {
    display: flex !important;
    background: transparent;
    border: none;
}
#webRTCVideo .vjs-control-bar .vjs-control {
    display: none !important;
}
#webRTCVideo .vjs-control-bar .dotText {
    display: inline-block !important;
    color: #d91e18;
    font-size: 26px;
    width: 3em !important;
}
#webRTCVideo .vjs-control-bar .recording-controls {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.5);
}
#webRTCVideo .vjs-control-bar .durationClassLabel {
    display: inline-block !important;
    font-size: 15px;
    color: white;
}
#webRTCVideo .vjs-control {
    width: 4em !important;
}
#webRTCVideo .vjs-time-divider {
    display: none;
}
.videoPlayerDimensions {
    width: 100%;
    height: auto;
    min-height: 250px;
}
.skip-back::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f04a";
}
.skip-forward::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f04e";
}
.display-flex > div {
    position: relative;
    margin: 1rem auto;
}
.upload-info-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: white;
    background: rgba(0, 0, 0, 0.4);
    padding: 10px;
}
.recorder-container {
    position: relative;
}
.progress-container {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    -o-object-fit: contain;
    object-fit: contain;
}
.circle-background,
.circle-progress {
    fill: none;
}
.circle-background {
    stroke: #ddd;
}
.circle-progress {
    stroke: $blue-theme-main;
    stroke-linecap: round;
    stroke-linejoin: round;
}
.circle-text {
    font-size: 2em;
    font-weight: bold;
    fill: $blue-theme-main;
}
.image-carousel-container {
    height: 250px;
    position: relative;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.tns-outer {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 60px);
    margin: 0 30px;
}
.react-multi-carousel-list {
    position: absolute;
    bottom: 10px;
    width: 100%;
    .react-multiple-carousel__arrow:before {
        font-family: "Font Awesome 5 Free";
    }
    .react-multiple-carousel__arrow--left:before {
        content: "\f053";
    }
    .react-multiple-carousel__arrow--right:before {
        content: "\f054";
    }
}
.tns-item.thumbnail {
    transition: all 0.25s;
    height: 100px;
    max-height: 100px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    opacity: 0.9;
    position: relative;
    background-color: transparent;
    border: none;
    margin-bottom: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    &.selected,
    &:hover {
        opacity: 1;
        transform: scale(1.05, 1.05);
    }
    &.selected {
        border-bottom: 5px solid $theme-color;
    }
}
.react-multiple-carousel__arrow::before {
    font-weight: 900;
}
.react-multi-carousel-item {
    z-index: 1;
}
.react-multi-carousel-item:hover {
    z-index: 2;
}
.react-multiple-carousel__arrow {
    background: $blue-theme-main;
    &:hover {
        background: $blue-theme-main-dark;
    }
}
.loading-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    text-align: center;
    transform: translate(-50%, -50%);
    .text {
        color: white;
        background: rgba(0, 0, 0, 0.2);
        padding: 10px;
        border-radius: 5px;
    }
}
.error-info-container {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    color: white;
    background: rgba(0, 0, 0, 0.4);
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
    width: 90%;
}
.error-info-container {
    .error-message {
        text-align: center;
        padding-bottom: 5px;
        padding-top: 5px;
        font-weight: bold;
    }
}

@media only screen and (min-width: 800px) {
    .retry-button-container {
        max-width: 120px !important;
    }
}

.retry-button-container {
    max-width: 100px;
    margin: 0 auto;
    margin-top: 1rem;
    .retry-button {
        i,
        span {
            font-size: 15px;
            font-weight: bold;
        }
        i {
            padding-right: 8px;
        }
    }
}
.confirm-native-record {
    width: 60px;
    margin: auto;
    margin-top: 0.5rem;
    padding: 8px 0;
}
.MuiDialogTitle-root,
.MuiDialogContent-root {
    margin: 0 auto !important;
}
.disabled {
    pointer-events: none;
    opacity: 0.5;
}

// Theme specific colors here //
// Green theme START //
$green-theme-main-background: #ecf4ec;
$green-theme-main: #3fc741;
$green-theme-main-dark: #24b927;
$green-theme-secondary-hover: #cbebcb;

.audio-picker-container.green-theme {
    background: $green-theme-main-background;
    .audio-picker-choices {
        background: $green-theme-main-background;
    }
    .primary-button {
        background: $green-theme-main;
        &:hover {
            background: $green-theme-main-dark;
        }
    }
    .secondary-button {
        border: 1px solid $green-theme-main;
        color: $green-theme-main;
        &:hover {
            background: $green-theme-secondary-hover;
        }
        .fas {
            color: $green-theme-main;
        }
    }
    .menu {
        background: $green-theme-main-background;
    }
    .circle-progress {
        stroke: $green-theme-main;
    }
    .circle-text {
        fill: $green-theme-main;
    }
    .duration-warning-container {
        color: $green-theme-main;
        border: 1px solid $green-theme-main;
        #progress-bar {
            background: $green-theme-secondary-hover;
        }
    }
    .react-multiple-carousel__arrow {
        background: $green-theme-main;
        &:hover {
            background: $green-theme-main-dark;
        }
    }
}
// Green theme END //
// VidInvite orange-purple START //
$vidinvite-theme-main-background: linear-gradient(to bottom right, #facaca, #ffcad2);
$vidinvite-theme-main: #ff0202;
$vidinvite-theme-main-dark: #e40b0b;
$vidinvite-theme-secondary-hover: #ffa0a0;

.audio-picker-container.vidinvite-theme {
    background: $vidinvite-theme-main-background;
    .audio-picker-choices {
        background: $vidinvite-theme-main-background;
    }
    .primary-button {
        background: $vidinvite-theme-main;
        &:hover {
            background: $vidinvite-theme-main-dark;
        }
    }
    .secondary-button {
        border: 1px solid $vidinvite-theme-main;
        color: $vidinvite-theme-main;
        &:hover {
            background: $vidinvite-theme-secondary-hover;
        }
        .fas {
            color: $vidinvite-theme-main;
        }
    }
    .menu {
        background: $vidinvite-theme-main-background;
    }
    .circle-progress {
        stroke: $vidinvite-theme-main;
    }
    .circle-text {
        fill: $vidinvite-theme-main;
    }
    .duration-warning-container {
        color: $vidinvite-theme-main;
        border: 1px solid $vidinvite-theme-main;
        #progress-bar {
            background: $vidinvite-theme-secondary-hover;
        }
    }
    .react-multiple-carousel__arrow {
        background: $vidinvite-theme-main;
        &:hover {
            background: $vidinvite-theme-main-dark;
        }
    }
}
// Vidinvite orange-purple theme END //
